import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import SEO from "@components/Seo"

import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
import PageHeader from "@components/blocks/common/PageHeader"
import NewsList from "@components/blocks/news/List"
import CategoryList from "@components/blocks/news/CategoryList"
import Pagination from "@components/blocks/news/Pagination"

import css from "@css/components/blocks/common/Page.module.styl"

export default function news({data, pageContext,...props})
{
    console.log('==========',data,pageContext)
    console.log('==========',props)
    console.log(pageContext.post)

    const newsData = data.allWordpressPost.edges

    return (
        <React.Fragment>
            <SEO title={'NEWS'} />
            <div className={css.container}>
                <PageHeader l1="NEWS" l2="ニュース"/>
                <div className={css.content}>
                    <div className={css.categoryList}>
                        <CategoryList isIndex={true}/>
                    </div>
                    <NewsList data={newsData}/>
                    <div className={css.pagination}>
                        <Pagination pageContext={pageContext}/>
                    </div>
                </div>
            </div>
            <FooterSns/>
            <Footer/>
        </React.Fragment>
    )
}

export const pageQuery = graphql`
query CategoryPage($name: String!, $limit: Int!, $skip: Int!) {
    site {
        siteMetadata {
            title
        }
    }
    allWordpressPost(
        filter: { categories: { elemMatch: { name: { eq: $name } } } }
        sort: { fields: date, order: DESC }
        limit: $limit
        skip: $skip
    ) {
        totalCount
        edges {
            node {
                id
                slug
                content
                date(formatString: "YYYY-MM-DD")
                title
                excerpt
                path
                categories {
                    name
                    slug
                }
                featured_media {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 640) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        publicURL
                    }
                }
                status
            }
        }
    }
 }
`
