import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { globalHistory } from '@reach/router'

import Link from "@components/Link"

import css from "@css/components/blocks/news/CategoryList.module.styl"

export default function PageCategoryList({isIndex=false})
{
    const { allWordpressCategory } = useStaticQuery(graphql`
        query NewsCategoryListQuery {
            allWordpressCategory {
                edges{
                    node {
                        id
                        name
                        slug
                    }
                }
            }
        }
    `)
    let pathname = globalHistory.location ? globalHistory.location.pathname : null
    let data = allWordpressCategory.edges
    // console.log('>>>>>>>>>>>====', data)
    return(
        <ul className={css.container}>
            <li className={css.item} key='all'>
            { isIndex ?
                <Link to="/news/">ALL</Link>
                :
                <span className={css.current}>ALL</span>
            }
            </li>
            {
                data.map(( {node}, i)=>{
                    const { name, slug, wordpress_id } = node
                    const to = `/news/category/${slug}/`
                    const check = pathname.indexOf(slug)
                    console.log(check)

                    return(
                        <li key={i} className={css.item}>
                            { check === -1 ?
                                <Link to={to}>{name}</Link>
                                :
                                <span className={css.current}>{name}</span>
                            }
                        </li>
                    )
                })
            }
        </ul>
    )
}
