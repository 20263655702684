import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { globalHistory } from '@reach/router'
import browser from 'browser-detect'

import Link from "@components/Link"

import css from "@css/components/Pagination.module.styl"
import DotSvg from "@images/Pagination__img__dot.svg"

export default function Pagination({pageContext, ...props})
{
    const browsed  = React.useRef(browser())

    const {
        pageNumber,//current
        humanPageNumber,
        skip,//スキップ
        limit,//表示量
        numberOfPages,//max
        previousPagePath,
        nextPagePath,
        slug
    } = pageContext
    console.log(">>>>>>>>>>")
    console.log(slug)
    let range = 2
    let min = pageNumber - range
    min = min < 0 ? 0 : min
    let max = pageNumber + range + 1
    max = max > numberOfPages ? numberOfPages : max

    if( max > numberOfPages ){
        min = pageNumber - (range * 2 - (numberOfPages - pageNumber))
        min = min < 0 ? 0 : min
    }

    //1...2,3,4...10のような形にしたい
    //レンジ外にもある場合には表示する形にすればいい

    let doms = []
    let path = slug ? `${slug}/` : ``
    let path_cat = slug ? "category/" : ""

    console.log('min',min, numberOfPages, pageNumber)

    //top
    if( min > 0 ){

        doms.push(
            <li className={css.item} key={`last`}>
                <Link to={`/news/${path_cat}${path}`}>1</Link>
            </li>
        )

        if( min >= 2 ){
            doms.push(
                <li className={css.item_dot} key={`dot1`}>
                    <span><DotSvg/></span>
                </li>
            )
        }
    }

    //middle
    let max2 = max
    let min2 = min

    if (max < numberOfPages ){
        if (pageNumber == 0 ) {
            max2 = max + 2
        } else if (pageNumber == 1) {
            max2 = max + 1
        } else {
            max2 = max
        }
    }

    console.log(">", max, numberOfPages)

    if ( pageNumber == numberOfPages - 1  ){
        min2 = min - 2
    } else if (pageNumber == numberOfPages - 2 ){
        min2 = min - 1
    }
    if( min2 < 0 ) {
        min2 = 0
    }
    for(let i = min2; i < max2; i++){
        let num = i + 1
        let to = `/news/${path_cat}${path}${num === 1 ? '' : `${num}/` }`

        doms.push(
            <li className={css.item} key={`item${i}`}>
                {
                    pageNumber !== i ?
                    <Link to={to}>{num}</Link>
                    :
                    <span>{num}</span>
                }
            </li>
        )
    }

    //back
    if( max < numberOfPages ){
        if( numberOfPages - max !== 1){
            doms.push(
                <li className={css.item_dot} key={`dot2`}>
                    <span><DotSvg/></span>
                </li>
            )
        }

        //last
        doms.push(
            <li className={css.item} key={`last2`}>
                <Link to={`/news/${path_cat}${path}${numberOfPages}/`}>{numberOfPages}</Link>
            </li>
        )
    }


    return(
        <ul className={css.container}>
            {doms}
        </ul>
    )
}
